body {
  background: #fff;
  margin: 0;
  font-family: "Calibri", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0404B4;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
  font-weight: bold;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 0.5em;
}

.content {
  background-color: #f2f2f2;
  padding-top: 2em;
  padding-bottom: 1em;
}

.btn-primary {
  color: #0404B4;
  background-color: #B8DEE2;
  border-color: #0404B4;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #0404B4;
  background-color: #3C8C93;
  border-color: #0404B4;
}

.form-control {
  color: #3C8C93;
}