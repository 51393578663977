
.brand-highlight {
  color: #a6dee3;
}

/* color: #a6dee3; */
.App {
  text-align: left;
}

.App-header {
  margin-bottom: 1em;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
}

.App-header-content {

}

.App-footer {
  margin-top: 1em;
  background-color: #d9d9d9;;
}

.navbar-inner {
  min-height: 0px;
}
