.Search {
    margin-top: 1em;
    width: 100%;
}

.Search-input {
    margin-bottom: 2em;
}

.Search-results ul {
    list-style: none;
    padding: 0;
}

.Search-filters .badge {
    float: right;
}

.Search-filters .list-group {
    margin-top: 2em;
    margin-bottom: 2em;
}